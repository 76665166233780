import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { HtmlPostRepo } from "./HtmlPostRepo";

import Prism from "prismjs";
import "prismjs/components/prism-csharp";
import ReactMarkdown from 'react-markdown';


type BlogPostCardProps = {
  title: string,
  description: string,
  date: Date,
}

type MarkdownState = {
  markdown: string
}

class BlogPost extends React.Component<RouteComponentProps<{ postId: string }>, MarkdownState> {
  constructor(props: RouteComponentProps<{ postId: string }>) {
    super(props);
    this.state = { markdown: 'loading...' };
  }

  public componentDidMount() {
      Prism.highlightAll();
  }

  public componentDidUpdate() {
    Prism.highlightAll();
  }

  public componentWillMount() {
    if (!this.htmlPostRepoArticle()) {
      // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
      fetch(`\/posts\/${this.props.match.params.postId}.md`).then(res => res.text()).then(text => {
        console.log(text);
        this.setState({ markdown: text });
      });
    }
  }

  public render() {
    if (this.props.match.params.postId === "argument-exception-best-practices") {
      return HtmlPostRepo.argumentExceptions();
    }
    if (this.props.match.params.postId === "nlog-quick-start") {
      return HtmlPostRepo.nlogQuickStart();
    }
    //const { markdown } = this.state;
    return (<ReactMarkdown source={this.state.markdown} />);
  }

  public htmlPostRepoArticle() : boolean {
    return this.props.match.params.postId === "argument-exception-best-practices" ||
      this.props.match.params.postId === "nlog-quick-start";
  }
};

export default BlogPost;
