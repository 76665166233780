import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';


type ProjectProps = {
  title: string,
  description?: string,
  imgUrl: string,
  downloadUrl?: string,
  githubUrl: string,
}

class Projects extends React.PureComponent {
  public render() {
    return (
      <React.Fragment>
        <h1>Projects</h1>
        <ul>
          <Project
            title="Total Uninstaller"
            description="Windows software uninstaller. Especially useful for speeding up Visual Studio by removing some of the bloat."
            imgUrl="https://github.com/tsasioglu/Total-Uninstaller/raw/master/TotalUninstaller/img/screenshot.png"
            downloadUrl="https://github.com/tsasioglu/Total-Uninstaller/blob/master/TotalUninstaller/bin/TotalUninstaller.exe?raw=true"
            githubUrl="https://github.com/tsasioglu/Total-Uninstaller">
          </Project>

          <Project
            title="Escape Pod"
            description="Escape Pod is a podcast downloader. It removes the need for iTunes and provides a lightweight, modern app for downloading podcast episodes. All you need is the .xml RSS URL."
            imgUrl="https://raw.githubusercontent.com/tsasioglu/Escape-Pod/master/EscapePod/img/screenshot.png"
            downloadUrl="https://github.com/tsasioglu/Escape-Pod/raw/master/EscapePod/bin/EscapePod.exe"
            githubUrl="https://github.com/tsasioglu/Escape-Pod">
          </Project>

          <Project
            title="Escape Pod JS"
            description="Web version of Escape Pod podcast downloader."
            imgUrl="https://github.com/tsasioglu/EscapePodJS/raw/master/img/screenshot.png"
            githubUrl="https://github.com/tsasioglu/EscapePodJS">
          </Project>

          <Project
            title="Google Location History Mapper"
            description="Plots Google Latitude (now 'Takeout') location history on a map."
            imgUrl="https://github.com/tsasioglu/Latitude-Mapper/blob/master/GoogleMapping/img/latitude_mapper.jpg?raw=true"
            githubUrl="https://github.com/tsasioglu/Latitude-Mapper">
          </Project>

          <Project
            title="Keyboard Tester"
            description="Windows keyboard tester. Shows currently pressed keys (dark blue) and previously pressed keys (light blue)."
            imgUrl="https://github.com/tsasioglu/Keyboard-Tester/raw/master/KeyboardTester/img/tester.png"
            downloadUrl="https://github.com/tsasioglu/Keyboard-Tester/raw/master/KeyboardTester/bin/KeyboardTester.exe"
            githubUrl="https://github.com/tsasioglu/Keyboard-Tester">
          </Project>
        </ul>
      </React.Fragment>
    );
  }
};

class Project extends React.PureComponent<ProjectProps> {
  public render() {
    return (
      <React.Fragment>
        <li className="projectLi">
          <img src={this.props.imgUrl} className="projectImg" alt={this.props.title} />
          <div className="projectInfo">
            <h2>{this.props.title}</h2>
            <p>{this.props.description}</p>

            <div>
              <a href={this.props.downloadUrl} className="snazzyButton blue">Download</a>
              <a href={this.props.githubUrl} className="snazzyButton blue">Github</a>
            </div>
          </div>
        </li>
      </React.Fragment>
    );
  }
};

export default Projects;
