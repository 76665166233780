import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import "prismjs/themes/prism-okaidia.css";

export class HtmlPostRepo {
  public static argumentExceptions() {
    return (
      <div>
        <h1>ArgumentException Best Practices</h1>
        30 Jun 2015
        <br />
        <section className="padded">
          The <span className="word-emphasis">ArgumentException</span> (and its derived types) are probably<sup>[1]</sup> the most commonly thrown exceptions in .NET after Exception itself, but they&apos;re often used incorrectly, and the MDSN guidelines are somewhat vague.

          <br /><br />
          There are three types of <span className="word-emphasis">ArgumentException</span> in .NET; the <span className="word-emphasis">ArgumentException</span> itself and its two derived types <span className="word-emphasis">ArgumentNullException</span> and <span className="word-emphasis">ArgumentOutOfRangeException</span>. Let&apos;s explore.

        </section>

        <h3>ArgumentNullException</h3>
        <section className="padded">
          The <span className="word-emphasis">ArgumentNullException</span> is usually thrown right at the start of a method, as part of a precondition check. If possible, parameters should be checked in signature order to aid discoverability and debugging.
<br /><br />
          A less common use for the <span className="word-emphasis">ArgumentNullException</span> is to notify the caller when a method was just about to call into another method with a null argument, generated from a caller-provided parameter. The caveat of course being that you need to know somehow that it&apos;s invalid to pass null to the inner method. But if you can guarantee this, you benefit from being able to throw earlier, and with a more meaningful message.


                </section>


        <br />

        <div className="card">
          <div className="card-header">Argument vs. Parameter</div>
          <div className="card-body">
            The generally accepted convention which .NET follows<sup>[2]</sup> is to call the things being passed into a method the arguments, and the things within a method parameters.
          </div>
        </div>
        <br />

        <h3>ArgumentOutOfRangeException</h3>
        <section className="padded">
          The <span className="word-emphasis">ArgumentOutOfRangeException</span> should be thrown whenever a parameter is not null, but still out of the range of acceptable values. A constructor that takes a string message should always be used, and include a description of the valid range of values.
                     <br /><br />
          Like the <span className="word-emphasis">ArgumentNullException</span>, when calling the single string constructor the parameter name should be passed in, and the created exception will have the friendly message <code className="cs">&quot;Specified argument was out of the range of valid values. Parameter name: foo&quot;</code>.
                </section>

        <h3>ArgumentException</h3>
        <section className="padded">
          <span className="word-emphasis">ArgumentException</span> is the concrete base class of the other two. It should only be used when one of the more specific types are not appropriate, which is usually when two or more arguments are not compatible with each other.
                     <br />
          <br />It should not be used with a single string parameter denoting a parameter name (e.g. <code className="cs">throw new ArgumentException(&quot;foo&quot;)</code>), as this violates the signature (<code className="cs">public ArgumentException(string message)</code>), by providing no message with details of the issue.


                </section>
        <br />
        <br />
        <br />
        <br />
        <br />


        <section className="padded">
          [1] At least according to GitHub: Exception has 4.3m usages, ArgumentException (all varieties) has 1.84m, InvalidOperationException has 460k.
                    <br />
          [2] The Main method signature being the obvious exception.
                </section>


      </div>
    );
  }

  public static nlogQuickStart() {
    return (
      <div>
        <h1>NLog (really) quick start</h1>
        23 May 2015
        <br />
                <h2>Why NLog?</h2>
                <section className="padded">
                It's powerful, actively developed, has decent documentation, a wide user base and supports Silverlight and Windows Phone. It might take an extra 5 minutes setting up the base config, but it's worth it.</section>
                
        <h2>3 Step Setup</h2>

        <section className="padded">
          <ol className="list-unstyled">
                       
                        <h4><li>1. Install using NuGet </li></h4>
                        <h4><li>2. Add the following to your App.config inside the <code className="xml">&lt;configuration&gt;</code> element</li></h4>

            <pre>
              <code className="language-xml">
{`<configSections>
    <section name="nlog" type="NLog.Config.ConfigSectionHandler, NLog"/>
</configSections>

<nlog xmlns="http://www.nlog-project.org/schemas/NLog.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
    <targets>
        <target name="log" xsi:type="File" fileName="C:/Temp/log.txt"/>
    </targets>
    <rules>
        <logger name="log" writeTo="log"/>
    </rules>
</nlog>`}
              </code>
            </pre>
           
            <br />

            <div className="card">
              <div className="card-header">Exception: "Only one &lt;configSections&gt; element allowed per config file and if present must be the first child of the root"</div>
              <div className="card-body">

                If you get this exception, make sure the <code className="language-xml">&lt;configsection&gt;</code> is at the top of the file, just under <code className="language-xml">&lt;configuration&gt;</code>.
                </div>
                </div>
                                      <br />

                  This config is set up to create a log file at <code className="laguage-xml">C:/Temp/log.txt</code>, and append to it. As this just creates one ever expanding file, it's only really useful for debugging. See instructions below on configuring NLog to create a new timestamped log file on each application launch.
                  <br /><br />

            <li><h4>3. Now all you have to do is get the Logger object and log from it</h4>
              <pre><code className="language-csharp">
{`Logger _logger = LogManager.GetLogger("log");
_logger.Error("Foo");
_logger.Info("Bar");
// etc...`}<br />
              </code></pre><br />
              
              </li>
            
          </ol>        
        </section>

        <h3>Optional Config</h3>
        <ol className="list-unstyled">
        <li>
          <section className="padded">
            <h3>Fresh log file per application launch, named by creation timestamp</h3>
              <pre><code className="language-xml">{`<target name="log" xsi:type="File" fileName="C:/Temp/log_\${cached:\${longdate}:cached=true}.txt">`}</code></pre>
          </section>
          </li>

          <li>
            <section className="padded">
              <h3>Exception support with stacktrace (also note the bonus <code className="xml">{"${longdate}"}</code> to get timestamped log lines)</h3>
              <pre><code className="language-xml">{"<target name=\"log\" xsi:type=\"File\" fileName=\"C:/Temp/log.txt\" layout=\"${longdate} ${message} ${exception:format=tostring}\">"}
              </code>
              </pre>
              <pre><code className="language-csharp">
{`Exception e = ...
_logger.Error("Uh oh: ", e);`}</code></pre>
            </section>
          </li>
          <li>
            <section className="padded">
              <h3>Log to <code className="language-xml">%AppData%/YourAppName</code> (no need to create this beforehand)</h3>
              <pre><code className="language-xml">{"<target name=\"log\" xsi:type=\"File\" fileName=\"${specialfolder:folder=ApplicationData}/YourAppName/log.txt\">"}
              </code>
              </pre>
            </section>
          </li>
          <li>
            <section className="padded">
              <h3>Specify minimum logging level of warning</h3>
              <pre><code className="language-xml">&lt;logger name="log" writeTo="log" minlevel="Warning"/&gt;</code></pre>
            </section>
          </li>
          <li>
            <section className="padded">
              <h3>Class specific config</h3>
              <pre><code className="language-xml">
{`<!-- Log messages from Class1 to the target named log1 -->
<logger name="Name.Space.Class1" writeTo="log1" />
<!-- Log messages from any class in the Name.Space2 namespace to the target named log2 -->
<logger name="Name.Space2.*" writeTo="log2" />`}
                        <br /></code></pre>
              <pre><code className="language-csharp">Logger _logger = LogManager.GetCurrentClassLogger();
                </code></pre>

            </section>
          </li>
          <li>
            <section className="padded">
              <h3>Enable Visual Studio intellisense</h3>
              Download
              <code className="language-xml"><a href="http://www.nlog-project.org/schemas/NLog.xsd">http://www.nlog-project.org/schemas/NLog.xsd</a></code> somewhere (
              <code className="language-xml">C:\Program Files (x86)\Microsoft Visual Studio 12.0\Xml\Schemas</code> is a good place). 
              Go to App.config properties -> Schemas -> Add NLog.xsd.
                        </section>
          </li>
        </ol>
        <br />

        <h2>Putting it all together</h2>
        <section className="padded">
          <pre><code className="language-xml">
{`<configuration>
    <configSections>
        <section name="nlog" type="NLog.Config.ConfigSectionHandler, NLog"/>
    </configSections>

    <nlog xmlns="http://www.nlog-project.org/schemas/NLog.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
        <targets>
            <target name="log" xsi:type="File"
                    fileName="\${specialfolder:folder=ApplicationData}/YourAppName/log_\${cached:\${longdate}:cached=true}.txt"
                    layout="\${longdate} \${message} \${exception:format=tostring}">
        <targets>
        <rules>
            <logger name="log" writeTo="log" minlevel="Warning"/>
        </rules>
    </nlog>
</configuration>`}</code></pre>
        </section>

        <h2>Dependency bundling</h2>
        If you're using a dependency bundler such as <a href="https://github.com/Fody/Costura">Fody/Costura</a> you'll need to define the config programatically on startup:<br /><br />
        <pre><code className="language-csharp">
{`var config = new LoggingConfiguration();

var target      = new FileTarget();
target.Name     = "log";
target.FileName = @"\${specialfolder:folder=ApplicationData}/YourAppName/log_\${cached:\${longdate}:cached=true}.txt";
target.Layout   = @"\${longdate} \${message} \${exception:format=tostring}";
config.AddTarget("log", target);

var rule = new LoggingRule("*", LogLevel.Trace, target);
config.LoggingRules.Add(rule);

LogManager.Configuration = config;`}
        </code></pre>
        <br />
        <h3>More @ <a href="https://github.com/nlog/NLog/wiki/Configuration-file">Github docs</a></h3>
        <br /><br />

      </div>
    );
  }
};
