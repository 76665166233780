import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from "react-router-dom";

import BlogPostsIndex from "../assets/BlogPostsIndex.json"

type BlogPostCardProps = {
  title: string,
  date: Date,
  postId: string,
}

type BlogPostIndexItem = {
  title: string,
  date: string,
  postId: string,
  postType: string,
}


class Blog extends React.PureComponent {
  public render() {
    console.log(BlogPostsIndex);
    return (
      <React.Fragment>
        <h1>Blog</h1>
        <ul>
          {BlogPostsIndex.map((post: BlogPostIndexItem) => 
            <BlogPostCard
              title={post.title}
              date={new Date(post.date)}
              postId={post.postId}
            />
          )}
        </ul>
      </React.Fragment>
);
}
};

class BlogPostCard extends React.PureComponent<BlogPostCardProps> {

  formatDate(date: Date) {
    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-gb', dateOptions);
  }

  public render() {
    return (
      <React.Fragment>
        <div className="blog-post">
          <Link to={`/blog-post/${this.props.postId}`}><h4>{this.props.title}</h4></Link>
          <p>{this.formatDate(this.props.date)}</p>
          


        </div>
      </React.Fragment>
    );
  }
};

export default Blog;
