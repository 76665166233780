import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Projects from './components/Projects';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';

import './custom.css'

export default () => (
  <Layout>
    <Route path='/projects' component={Projects} />
    <Route exact path='/' component={Blog} />
    <Route exact path='/blog-post/:postId' component={BlogPost} />
  </Layout>
);
